import React from 'react';
import {Page} from "./page";
import {ApiStore} from "../stores/api-store";
import {BaseStore} from "../stores/base-store";
import {inject, observer} from 'mobx-react';
import {Card, Col, Container, ListGroup, Row} from 'react-bootstrap';
import {renderUrl} from './url-renderer';
import {Helmet} from 'react-helmet';
import {pushPageView} from "../utils/gtm-utils";

//------------------------
// RIDE OCTANE PAGE:
//------------------------

/**
 * Implements a ride-octane-ux "Page" for our HTML sitemap view
 */
export function sitemapHtml() {
    const store = Page.createStore({
        isSitemapHtml: true,
        stores: {
            apiStore: new ApiStore(),
            sitemapHtmlStore: new SitemapHtmlStore(),
        },
    });

    const component = Page.createComponent({
        content: <SitemapHtmlPageContent/>,
    });

    pushPageView('sitemap');
    return {store, component};
}

//------------------------
// COMPONENTS:
//------------------------

/**
 * Component that renders a model entry in the HTML sitemap
 * @param vehicleModel, as represented by the api-server
 */
const ModelName = ({vehicleModel}) => {
    const name = [vehicleModel.vehicle_make.name, vehicleModel.name].join(' ');
    return (
        <a href={renderUrl(`/${vehicleModel.slug}`)}>{name}</a>
    );
};

/**
 * Component that renders a make entry in the HTML sitemap
 * @param vehicleMake, as represented by the api-server
 */
const MakeName = ({vehicleMake}) => {
    if(vehicleMake.slug) {
        return (
            <Card.Link href={renderUrl(`/${vehicleMake.slug}`)}>{vehicleMake.name}</Card.Link>
        );
    }
    else {
        return vehicleMake.name;
    }
};


/**
 * Component that renders a Make entry in our HTML sitemap page
 * @param vehicleMake, as represented by the api-server
 * @param vehicleModels, array of Vehicle Models as represented by the api-server
 */
const MakeComponent = ({vehicleMake, vehicleModels}) => (
    <Col lg={3}>
    <Card className="mt-3 mb-3">
        <Card.Header>
            <MakeName vehicleMake={vehicleMake}/>
        </Card.Header>
        <ListGroup variant="flush">
        {
            vehicleModels.map((vehicleModel) => (
                <ListGroup.Item key={vehicleModel.name}>
                    <ModelName vehicleModel={vehicleModel}/>
                </ListGroup.Item>
            ))
        }
        </ListGroup>
    </Card>
    </Col>
);

/**
 * React component that renders the page content unique
 * to the sitemap HTML page.
 */
export const SitemapHtmlPageContent = (
    inject('sitemapHtmlStore')(observer(
        function({sitemapHtmlStore}) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>Sitemap | Octane.co</title>
                    </Helmet>
                    <Container>
                        <h1 className="h3 mt-3">Browse Models</h1>
                        <Row> {
                            Object.keys(
                                sitemapHtmlStore.modelsByMake
                            ).sort().map((key) => (
                                <MakeComponent
                                    key={key}
                                    vehicleMake={sitemapHtmlStore.modelsByMake[key][0]['vehicle_make']}
                                    vehicleModels={
                                        sitemapHtmlStore.modelsByMake[key]
                                    }
                                />
                            ))
                        } </Row>
                    </Container>
                </React.Fragment>
            );
        }
    ))
);

//------------------------
// PAGE STORE:
//------------------------

/**
 * In ride-octane-ux style, this is a store to maintain the data backing
 * our sitemap HTML page.
 */
class SitemapHtmlStore extends BaseStore {
    modelsByMake = null;

    preRenderSetup() {
        if (this.modelsByMake !== null) {
            this.isReady = true;
            return;
        } else {
            this.modelsByMake = {};
        }
        // Conveniently, BaseStore exposes access to a reasonable instance
        // of ApiStore:
        const apiStore = this.apiStore;

        this._getVehicleModels(apiStore).then((vehicleModels) => {
            vehicleModels.forEach((vehicleModel) => {
                if(!!vehicleModel.vehicle_make){
                    const vehicleMake = vehicleModel['vehicle_make'];
                    if (!this.modelsByMake[vehicleMake.name]) {
                        this.modelsByMake[vehicleMake.name] = [vehicleModel];
                    } else {
                        this.modelsByMake[vehicleMake.name].push(vehicleModel);
                    }
                }
                else{
                    console.error('No vehicle_make for vehicle model ' + vehicleModel.name + ' with uuid '+ vehicleModel.uuid);
                }
            });
            this.isReady = true;
        }).catch(() => {
            console.error('Failed to obtain vehicle models for sitemap html store.');
            this.isReady = true;
        });
    }

    _getVehicleModels(apiStore) {
        const query = {
            fields: [
                'slug', 'name', 'vehicle_make', 'uuid'
            ],
            /* N2O-864: The models response is paginated, setting this to an
             * arbitrarily high number so we get all the vehicles back in a
             * single call
             */
            limit: 1000
        };

        const filterOutSlugless = (response) => {
            return response.results.filter(
                vehicle_model => vehicle_model.slug !== null
            );
        };

        return apiStore.fetch('models', {query: query}).then(result => {
            if (result.response.next !== null) {
                console.error('Model query response on sitemap was truncated. All vehicles may not have been displayed');
            }
            return filterOutSlugless(result.response);
        }).catch(() => {
            console.error(`sitemap html failed to obtain vehicle models`);
            return [];
        });
    }
}
